import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg">
          <div className="onas">
            <div className="wrapper onas">
              <header>
                <h1 className="logo">
                  <a href="/">Neutrogena</a>
                </h1>
                <nav>
                  <a href="/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/neutrogena/o-nas/" className="section">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>| </span>
                  <a href="/budte-krasna/" className="link">
                    Buďte krásná
                   </a>
                   
                  <span />
                   
                  <span />
                </nav>
              </header>
              <div className="top">
                <h2>
                  O značce&nbsp;
                  <br /> NEUTROGENA&nbsp;<span>®</span>
                </h2>
                <p>&nbsp;</p>
                <div className="breadcrumb">
                  <a href="/">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>&gt;</span>
                  <a href="/neutrogena/o-nas/">NEUTROGENA®</a> <span>&gt;</span>
                  Historie
                </div>
              </div>
              <div></div>
              <div className="submenu">
                <div className="wrapper">
                  <a href="/neutrogena/o-nas/" className="link">
                    O nás
                  </a>
                  <a href="/neutrogena/historie/" className="current">
                    Historie
                  </a>
                  <a href="/neutrogena/norska-receptura/" className="link">
                    Norská receptura
                  </a>
                </div>
              </div>
              <div className="wrapper">
                <div className="content">
                  <section>
                    <div className="text half left">
                      <span className="border" />
                      <p></p>
                      <p>
                        <img title src="/assets/Uploads/hist1.jpg" alt />
                        <br />
                        1930
                        <br />
                        <br />
                        Historie značky NEUTROGENA<sup>®</sup> &nbsp;sahá až do
                        roku 1930, kdy Emanuel “Manny” Stolaroff založil malou
                        kosmetickou firmu Natone se sídlem v Los Angeles,
                        Kalifornie.
                        <br /> V prvních letech byla firma Natone dodavatelem
                        výrobků do salónů krásy, jejichž klienti byli většinou
                        lidé spojení s filmovým průmyslem. Proto není
                        překvapením, že nabídka firmy obsahovala takové
                        mimořádné položky jako např. řasy <br />s opravdovými
                        diamanty či vyrobené z kožešiny, nebo zpevňovače nehtů.
                        Ovšem již ve 40. letech začala firma vyrábět a
                        distribuovat kosmetiku na maloobchodní trh.
                        <br /> V roce 1950 se “Manny” vydal za novou inspirací
                        na cestu kolem světa. Aby zmátl konkurenci, zvolil si
                        pseudonym Mr. Idea a pod ním uváděl své nové nápady.
                      </p>
                      <p>&nbsp;</p>
                      <p />
                    </div>
                    <div className="text half right">
                      <p>
                        <br />
                      </p>
                      <p>
                        1954
                        <br />
                        <br />
                        <img
                          className="left"
                          title
                          src="/assets/Uploads/hist2.jpg"
                          alt="hist2"
                          width="264"
                          height="316"
                        />
                        Průlom nastal v roce 1954. &nbsp;“Manny” se dozvěděl
                        &nbsp;
                        <br />o neobvyklých vlastnostech jednoho typu mýdla
                        vyrobeného vnukem Napoleonova osobního lékaře – Dr.
                        Edmondem Fromontem. Toto jemné a pokožku nedráždící
                        mýdlo&nbsp;se rychle a snadno smylo a nezanechávalo na
                        pokožce stopy.&nbsp;Po umytí tímto unikátním mýdlem se
                        pokožka vrátila ke svému&nbsp;normálnímu pH velmi
                        rychle. Fascinován vlastnostmi tohoto mýdla se “Manny”
                        Stolaroff rozhodl dovážet ho do USA pod názvem
                        NEUTROGENA<sup>®</sup>.
                      </p>
                    </div>
                  </section>
                  <section>
                    <div className="bg hist1">
                      <div className="text right">
                        <h2>60.léta 20. století &nbsp;</h2>
                        <p>
                          Vysoce kvalitní mýdlo NEUTROGENA<sup>®</sup>&nbsp;tam
                          bylo okamžitě zaznamenáno a&nbsp;našlo obrovský trh v
                          obchodních domech a obchodech s kvalitní kosmetikou.
                          Jantarově žlutá kostka se brzy stala vlajkovým
                          výrobkem rychle se vyvíjejícího byznysu. Počátkem 60.
                          let se firma přejmenovala na společnost NEUTROGENA
                          <sup>®</sup>.
                        </p>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div className="bg hist2">
                      <div className="text left">
                        <h2>Lloyd Cotsen</h2>
                        <p>
                          Úspěch&nbsp;byl&nbsp;zpečetěn&nbsp;novým&nbsp;prezidentem&nbsp;společnosti&nbsp;Lloydem&nbsp;Cotsenem.
                        </p>
                        <p>
                          Zaměřil se
                          na&nbsp;konzistentní&nbsp;propagaci&nbsp;výhod&nbsp;mýdla&nbsp;zdravotnickým&nbsp;odborníkům.
                        </p>
                        <p>
                          Vždy&nbsp;vysoká&nbsp;kvalita&nbsp;kosmetiky,
                          kterou&nbsp;může&nbsp;dosvědčit&nbsp;několik&nbsp;generací,
                          stabilizovala&nbsp;pozici&nbsp;značky NEUTROGENA
                          <sup>®</sup>&nbsp;jako&nbsp;jednoho&nbsp;z
                          lídrů&nbsp;na trhu&nbsp;s kosmetikou v USA. &nbsp;
                        </p>
                        <p>&nbsp;</p>
                        <p>
                          Dalším mezníkem v historii společnosti byl vývoj
                          Norské receptury, unikátní receptury, která odpovídá
                          potřebám pokožky vystavené drsným klimatickým
                          podmínkám.
                        </p>
                      </div>
                    </div>
                  </section>
                  <section className="last">
                    <div className="bg hist3">
                      <div className="text right">
                        <h2>
                          90. léta 20. století &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp;
                        </h2>
                        <p>&nbsp;</p>
                        <p>
                          Značka se těší obrovské popularitě a důvěře po celém světě. Tato
                          reputace tkví v prioritních hodnotách značky –
                          oddanosti zákazníkovi a nadprůměrné péči věnované
                          kvalitě a vlastnostem výrobku, který doporučují
                          odborníci. &nbsp;
                        </p>
                        <p>&nbsp;</p>
                        <p>
                          V době, kdy je krása více než kdykoliv předtím spojena
                          se zdravím, představují výrobky NEUTROGENA<sup>®</sup>{" "}
                          nejlepší řešení, což dokazují rostoucí řady
                          spokojených zákazníků.
                        </p>
                      </div>
                    </div>
                  </section>
                  <div id="fb_67"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="bluebar">
            <div className="bar">
              <div className="wrapper">
                <a href="/neutrogena/o-nas/">
                  O ZNAČCE NEUTROGENA<sup>®</sup>
                </a>
                <span>|</span>
                <a href="/neutrogena/norska-receptura/">Norská receptura</a>
                <span>|</span>
                <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
                <span>|</span>
                <a href="/produkty/">
                  Zobrazit produkty NEUTROGENA<sup>®</sup>
                </a>
              </div>
            </div>
          </div>
          <Footer></Footer>
          <script
            dangerouslySetInnerHTML={{
              __html:
                "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
            }}
          />
        </div>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
